window.renderGraph = function(svgid, title, data) {
	let width = $("#app").width();
	let height = 500;

	// parse the id / time
	var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
	var formatTime = d3.timeFormat("%e %B");

	// set the ranges
	var x = d3.scaleTime().range([0, width]);
	var y = d3.scaleLinear().range([height, 0]);
  
	// append the svg obgect to the body of the page
	// appends a 'group' element to 'svg'
	// moves the 'group' element to the top left margin
	var svg = d3.select(svgid)
		.attr("width", width)
		.attr("height", (height+100))
		.attr("viewBox", `0 0 ` + (width+100) + ` ` + (height));

	svg.selectAll("*").remove();

	// add title to graph
	svg.append("text")
		.attr("x", ((width+100) / 2))
		.attr("y", -36)
		.attr("text-anchor", "middle")  
		.style("font-size", "24px") 
		.style("text-decoration", "underline")
		.text(title);

	var svg_graph = svg.append("g")
		.attr("transform", "translate(" + 50 + "," + -10 + ")");

	

	// add the tooltip box
	/*var tooltip = svg.append("rect")
		.attr("width", 160)
		.attr("height", 20)
		.attr("fill", "LightGoldenRodYellow ")
		.attr("stroke", "black")
		.attr("stroke-width", "2");
		tooltip.attr("x", 100);
		tooltip.innerHtml = "hello";*/

	// gridlines in x axis function
	function make_x_gridlines() {		
		return d3.axisBottom(x)
			.ticks(50)
	}
	// gridlines in y axis function
	function make_y_gridlines() {		
		return d3.axisLeft(y)
			.ticks(7)
	}

	// format the data
	data.forEach(function(d) {
		d.time = parseTime(d.time);
		d.value = +d.value;
	});

	// Scale the range of the data
	let minimum = d3.min(data, function(d) { return d.value; });
	let maximum = d3.max(data, function(d) { return d.value; });
	let average = d3.mean(data, function(d) { return d.value; });
	let last_value = data[data.length -1].value;
	let grid_margin = (maximum - minimum) * 0.1;
	x.domain(d3.extent(data, function(d) { return d.time; }));
	y.domain([minimum - grid_margin, maximum + grid_margin]);

	// add values to graph
	svg.append("text")
		.attr("x", ((width+100) / 2))
		.attr("y", height+50)
		.attr("text-anchor", "middle")  
		.style("font-size", "18px") 
		.text("Minimum: " + minimum + ", Average: " + (+(Math.round(average + "e+2")  + "e-2")) + ", Maximum: " + maximum + ", Difference: " + (+(Math.round((maximum-minimum) + "e+2")  + "e-2")));
	svg.append("text")
		.attr("x", (width-100))
		.attr("y", height+50)
		.attr("text-anchor", "right")  
		.style("font-size", "18px") 
		.text("Last value: " + last_value);

	// add the X gridlines
	svg_graph.append("g")			
	.attr("class", "grid")
	.attr("transform", "translate(0," + height + ")")
	.call(make_x_gridlines()
		.tickSize(-height)
		.tickFormat("")
	)

	// add the Y gridlines
	svg_graph.append("g")			
		.attr("class", "grid")
		.call(make_y_gridlines()
			.tickSize(-width)
			.tickFormat("")
		)

	// define the line
	var valueline = d3.line()
		.x(function(d) { return x(d.time); })
		.y(function(d) { return y(d.value); })
		.curve(d3.curveMonotoneX);

	svg_graph.selectAll(".dot")
		.data(data)
		.enter().append("circle") // Uses the enter().append() method
		.attr("class", "dot") // Assign a class for styling
		.style("fill", "#bb480f")
		.attr("cx", function(d, i) { return x(d.time); })
		.attr("cy", function(d) { return y(d.value) })
		.attr("r", 4)
		.on("mouseover", function(a, b, c) {
			/*console.log(b.value);
			tooltip.attr("x", 0)
				.attr("y", 0);
			tooltip.text = b.value;*/
		})
		.on("mouseout", function() {
			
		})
  
	// Add the valueline path.
	svg_graph.append("path")
		.data([data])
		.attr("class", "line")
		.attr("d", valueline)
		.style("fill", "none")
		.style("stroke", "#bb480f")
		.style("stroke-width", "2px");
  
	// Add the x Axis
	svg_graph.append("g")
		.attr("transform", "translate(0," + height + ")")
		.call(d3.axisBottom(x));
  
	// Add the y Axis
	svg_graph.append("g")
		.call(d3.axisLeft(y));	
}